:root {
   --primary-color: #4f52ff;
   --primary-color-light: #bdd0fb;
   --secondary-color: #fe7062;
   --secondary-color-light: #ffb7b2;
   --text-color: #000;
   --cardback-color: #f6f6f6;
   --mainback-color: #fff;
}

.darkmode {
   --primary-color: #4f52ff;
   --primary-color-light: #c8d2eb;
   --secondary-color: #fe7062;
   --secondary-color-light: #ffb7b2;
   --text-color: #fff;
   --cardback-color: #161616;
   --mainback-color: #101010;
}

/* Sign up .signbtn */

.signbtn {
   --hover-color: var(--primary-color-light);
   --arrow-width: 10px;
   --arrow-stroke: 2px;
   box-sizing: border-box;
   border: 0;
   border-radius: 20px;
   color: var(--text-color);
   padding: 1em 1.8em;
   background: var(--primary-color);
   display: flex;
   transition: 0.2s background;
   align-items: center;
   gap: 0.6em;
   font-weight: bold;
   margin: 0 auto;
}

.signbtn .arrow-wrapper {
   display: flex;
   justify-content: center;
   align-items: center;
}

.signbtn .arrow {
   margin-top: 1px;
   width: var(--arrow-width);
   background: var(--text-color);
   height: var(--arrow-stroke);
   position: relative;
   transition: 0.2s;
}

.signbtn .arrow::before {
   content: "";
   box-sizing: border-box;
   position: absolute;
   border: solid var(--text-color);
   border-width: 0 var(--arrow-stroke) var(--arrow-stroke) 0;
   display: inline-block;
   top: -3px;
   right: 3px;
   transition: 0.2s;
   padding: 3px;
   transform: rotate(-45deg);
}

.signbtn:hover {
   filter: brightness(1.3);
}

.signbtn:hover .arrow {
   background: var(--text-color);
}

.signbtn:hover .arrow:before {
   right: 0;
}

/* input of sign up form */

.input-form-sign {
   position: relative;
   font-family: Arial, Helvetica, sans-serif;
   width: fit-content;
   margin: 0 auto;
}

.input-form-sign .input {
   border: solid 1.9px #9e9e9e;
   border-radius: 1.3rem;
   background: none;
   padding: 1rem;
   font-size: 1rem;
   color: var(--text-color);
   transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input-form-sign .textUser {
   position: absolute;
   left: 15px;
   color: #666666;
   pointer-events: none;
   transform: translateY(1rem);
   transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input-form-sign .input:focus,
.input-form-sign input:valid {
   outline: none;
   box-shadow: 1px 2px 5px rgba(133, 133, 133, 0.523);
   background-image: linear-gradient(
      to top,
      rgba(182, 182, 182, 0.199),
      rgba(252, 252, 252, 0)
   );
   transition: background 4s ease-in-out;
}

.input-form-sign .input:focus ~ label,
.input-form-sign input:valid ~ label {
   transform: translateY(-95%) scale(0.9);
   padding: 0 0.2em;
   color: var(--text-color);
   left: 80px;
}

.input-form-sign .input:hover {
   border: solid 1.9px #000002;
   transform: scale(1.03);
   box-shadow: 1px 1px 5px rgba(133, 133, 133, 0.523);
   transition: border-color 1s ease-in-out;
}

/* Loading animationk */

.loader {
   position: absolute;
   top: 50%;
   left: 50%;
   z-index: 10;
   width: 160px;
   height: 100px;
   margin-left: -80px;
   margin-top: -50px;
   border-radius: 5px;
   background: #1e3f57;
   animation: dot1_ 3s cubic-bezier(0.55, 0.3, 0.24, 0.99) infinite;
}

.loader:nth-child(2) {
   z-index: 11;
   width: 150px;
   height: 90px;
   margin-top: -45px;
   margin-left: -75px;
   border-radius: 3px;
   background: #3c517d;
   animation-name: dot2_;
}

.loader:nth-child(3) {
   z-index: 12;
   width: 40px;
   height: 20px;
   margin-top: 50px;
   margin-left: -20px;
   border-radius: 0 0 5px 5px;
   background: #6bb2cd;
   animation-name: dot3_;
}
.container-loader {
   background-color: var(--mainback-color);
}

@keyframes dot1_ {
   3%,
   97% {
      width: 160px;
      height: 100px;
      margin-top: -50px;
      margin-left: -80px;
   }

   30%,
   36% {
      width: 80px;
      height: 120px;
      margin-top: -60px;
      margin-left: -40px;
   }

   63%,
   69% {
      width: 40px;
      height: 80px;
      margin-top: -40px;
      margin-left: -20px;
   }
}

@keyframes dot2_ {
   3%,
   97% {
      height: 90px;
      width: 150px;
      margin-left: -75px;
      margin-top: -45px;
   }

   30%,
   36% {
      width: 70px;
      height: 96px;
      margin-left: -35px;
      margin-top: -48px;
   }

   63%,
   69% {
      width: 32px;
      height: 60px;
      margin-left: -16px;
      margin-top: -30px;
   }
}

@keyframes dot3_ {
   3%,
   97% {
      height: 20px;
      width: 40px;
      margin-left: -20px;
      margin-top: 50px;
   }

   30%,
   36% {
      width: 8px;
      height: 8px;
      margin-left: -5px;
      margin-top: 49px;
      border-radius: 8px;
   }

   63%,
   69% {
      width: 16px;
      height: 4px;
      margin-left: -8px;
      margin-top: -37px;
      border-radius: 10px;
   }
}

.loaderr {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}

.card {
   width: 70px;
   height: 100px;
   margin: 0 10px;
   border-radius: 5px;
   background-color: #f5f5f5;
   animation: pulse 1.5s infinite;
}

@keyframes pulse {
   0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
   }
   50% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
   }
   100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
   }
}
