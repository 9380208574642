/*-------------------------  FONTS SECTION  ----------------------------*/
:root {
    --primary-color: #4f52ff;
    --primary-color-light: #bdd0fb;
    --secondary-color: #fe7062;
    --secondary-color-light: #ffb7b2;
    --text-color: #000;
    --cardback-color: #f6f6f6;
    --mainback-color: #fff;
}

.darkmode {
    --primary-color: #4f52ff;
    --primary-color-light: #c8d2eb;
    --secondary-color: #fe7062;
    --secondary-color-light: #ffb7b2;
    --text-color: #fff;
    --cardback-color: #161616;
    --mainback-color: #101010;
}

.btn-primary {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}
/* font-family: 'Montserrat', sans-serif; */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

main {
    box-sizing: border-box;
    background-color: var(--mainback-color) !important;
}

/* ----------------         NAVBAR SECTION               -------------------- */
.navi {
    background-color: var(--primary-color) !important;
    color: white !important;
    display: flex;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px !important;
}

.navi--logo {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 30px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    color: white;
    text-decoration: none;
    width: 50px;
    height: 50px;
}

.navbar-brand {
    margin-top: -10px;
}

.navi-linko {
    font-size: 18px !important;
    color: white !important;
    font-family: roboto !important;
    font-weight: 500 !important;
    text-decoration: none;
    margin-left: 10px;
}
.navi-linko:hover {
    color: var(--secondary-color) !important;
    text-decoration: none !important;
}

.icon {
    margin-top: 0;
    margin-left: 0;
    font-size: 30px;
    color: white;
}
.darkmode {
    border: none;
    background-color: transparent;
    color: white;
}

.Search-nav {
    padding: 10px 0;
    display: flex;
    background-color: var(--primary-color);
}
.Search-nav input {
    width: 500px;
    height: 40px;
    border: 0.5px solid #ccc;
    border-radius: 10px;
    padding: 0 20px;
    color: var(--text-color);
    background-color: var(--cardback-color);
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
}
.Search-nav input::placeholder {
    color: #b1b5bb;
}

.Search-nav button {
    border: 0.5px solid #ccc;
    border-radius: 10px;
    position: relative;
    right: -5px;
    color: var(--text-color);
    background-color: var(--secondary-color);
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
}

.toggol-nav {
    position: absolute;
    right: 24px;
    font-size: 30px;
    color: white !important;
    top: 8px;
    display: block !important;
    width: 50px;
    transition: 0.2s ease-in-out;
    border: none !important;
    background-color: transparent !important;
}
.toggol-nav.activated {
    transform: rotate(90deg);
}
.navi--add {
    margin-right: 70px;
}

/* ----------------         HERO PAGE SECTION               -------------------- */

.heropage {
    margin-top: 100px;
    position: relative;
}
.heropage .firstpart {
    background-image: url(./pics/heropage-light.svg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #494cf3;
}
.heropage .secondpart.abtt img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.heropage .firstpart.darkmode {
    background-image: url(./pics/heropage-dark.svg);
    background-repeat: no-repeat;
    background-size: cover;

    color: #494cf3;
}
.heropage .firstpart p {
    font-size: 50px;
    font-weight: 600;
    font-family: "roboto", sans-serif;
    margin-top: 100px;
    margin-left: 100px;
}
.heropage .firstpart p span {
    color: var(--secondary-color);
}
.heropage .firstpart h5 {
    font-weight: 400;
    font-family: "roboto", sans-serif;
    margin-top: 20px;
    margin-left: 100px;
    color: #7d97f4;
}

/*------------------        CARDS SECTION  ----------------------------*/

.cardono {
    background-color: var(--cardback-color);
    padding: 75px 125px !important;
    border-radius: 10px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.star-ico {
    color: var(--primary-color);
    font-size: 20px;
}
.product-cart-mini .cardi {
    background-color: var(--cardback-color);
    height: 250px;
    position: relative;
}
.product-cart-mini .cardi span  {
    object-fit: contain;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
}

.product-cart-mini .cardi span img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out !important;
}

.heart-ico {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    color: var(--secondary-color);
    transition: 0.3s ease-in-out;
    background-color: transparent;
    border: none;
}

.product-cart-mini .cardi span img:hover {
    transform: scale(1.1);
}
.cord h5 {
    height: 5rem;
}
.cart-btn {
    color: var(--text-color);
    border: 1px solid var(--text-color);
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    padding: 10px 20px;
    background-color: transparent;
}

.cart-btn:hover {
    background-color: var(--secondary-color);
    color: var(--text-color);
    border: 1px solid var(--secondary-color);
}

/* ----------------             ABOUT CONTACT              -------------------- */

.services i {
    font-size: 50px;
    color: var(--secondary-color);
}

.read-or-hide {
    color: var(--secondary-color);
    cursor: pointer;
}
.cardd {
    background-color: var(--cardback-color) !important;
    border-radius: 10px !important;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
}

.formilo {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    gap: 20px;
    align-content: space-around;
}

.formilo label {
    font-size: 15px;
    font-weight: 500;
    color: var(--text-color);
}

.formilo input {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px 20px;
    color: black;
    background-color: #e5e7eb;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
}
.formilo textarea {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px 20px;
    color: black;
    background-color: #e5e7eb;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
}

.formilo button {
    border: 1px solid var(--secondary-color);
    border-radius: 10px;
    padding: 10px 20px;
    color: var(--text-color);
    background-color: var(--secondary-color);
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.formilo button:hover {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

/* ----------------             Login / Signup              -------------------- */

.login .firstpart {
    background-color: var(--primary-color-light);
    height: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.login .secondpart {
    height: 100vh !important;
    overflow: hidden !important;
}

.signup .firstpart {
    background-color: var(--secondary-color-light);
}

.login.signop .secondpart {
    height: auto !important;
}

.login .firstpart button,
button.err {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 20px;
    left: 15px;
}

.login .firstpart button svg {
    font-size: 30px;
    color: var(--text-color);
}

.btn-sign {
    background-color: var(--secondary-color) !important;
    color: var(--text-color);
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    margin: 20px;
    padding: 12px 24px;
}
.btn-sign:hover {
    background-color: var(--primary-color) !important;
    color: var(--text-color);
}

.error-page {
    height: calc(100vh - 200px);
}

.btn-login {
    background-color: var(--primary-color) !important;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    margin: 20px;
    padding: 12px 24px;
}
.btn-login:hover {
    background-color: var(--secondary-color) !important;
    color: var(--text-color);
}

.login.signop {
    height: auto;
}

.login.signop .firstpart {
    height: auto;
}

/* ----------------            Account              -------------------- */

.account .firstpart {
    height: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    color: black !important;
}

.account .account-img {
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: 20px;
    position: relative;
}
.account .account-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.account .account-img button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: var(--primary-color-light);
    border: none;
    padding: 2.5px 5px;
    cursor: pointer;
}

.account .firstpart a {
    color: var(--text-color);
    font-size: 12px;
    text-decoration: none;
}
.account .firstpart a h3 {
    font-size: 20px;
    font-weight: 500;
    color: black;
}
.account .firstpart h5 {
    color: black;
    opacity: 0.5;
}
.account .firstpart h3 {
    font-weight: 600;
}

.account .firstpart a h3:hover {
    color: var(--primary-color);
    font-weight: 600;
}

.text-gray {
    color: var(--text-color);
    opacity: 0.5;
}
.account .firstpart {
    box-shadow: 1px 5px 60px 0px #100a886b;
}

/* ----------------            Cart              -------------------- */
.cart-cards {
    background-color: var(--cardback-color);
    margin: 20px;
    padding: 20px;
}
.cart-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 20px;
}
.cart-title:hover {
    color: var(--secondary-color);
    cursor: pointer;
}
.cart-price {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
}
.cart-infos {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}
.quantity-input {
    display: flex;
    align-items: center;
}

.quantity-field {
    width: 50px;
    text-align: center;
    border: none;
    border-radius: 3px;
    background-color: #f2f2f2;
    margin: 0 10px;
}

.ad-img {
    width: 100%;
    overflow: hidden;
    margin-top: 2rem 0;
    contain: content;
    max-height: 350px;
}

/* ----------------            Account              -------------------- */
.account .firstpart {
    height: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    background-color: var(--primary-color-light);
    border: none;
    border-radius: 20px;
}

/*------------------------- footer -------------------------*/
.footer-links ul a {
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0;
    display: block;
}
.dropee {
    position: absolute !important;
}

/* ----------------             Screens              -------------------- */

@media (max-width: 767px) {
    svg,
    .smalli {
        width: 26px;
        height: 26px;
    }
    .navi--add {
        position: absolute;
        right: 70px;
        top: 22px;
        margin-right: 0;
    }
    .navi-linko {
        font-size: 18px !important;
        color: white !important;
        font-family: roboto !important;
        font-weight: 500 !important;
        text-decoration: none;
        margin-left: 7px;
    }
    .Search-nav input {
        width: 300px;
    }
    .heropage .firstpart {
        background-size: contain;
    }
    .heropage .firstpart p {
        font-size: 40px;
        margin-left: 20px;
    }
    .heropage .firstpart h5 {
        margin-left: 20px;
    }
    .cardono {
        background-color: var(--cardback-color);
        padding: 25px 50px !important;
        border-radius: 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
            rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }
    .heropage .secondpart.abtt img {
        position: relative;
        position: static;
        top: 0%;
        transform: translateY(-0%);
    }
    .cord h5 {
        height: 4.5rem;
        font-size: 1rem;
    }

    .login .firstpart {
        display: none;
    }
    .cart-title {
        font-size: 16px;
        font-weight: 600;
        color: var(--text-color);
        margin-bottom: 5px;
    }
    .cart-title:hover {
        color: var(--secondary-color);
        cursor: pointer;
        height: auto;
    }
    .cart-price {
        font-size: 14px;
        font-weight: 600;
        color: var(--text-color);
        margin: 0;
    }
    .cart-cards {
        margin: 10px;
        padding: 10px;
    }
    .cart-infos {
        font-size: 12px;
        font-weight: 500;
        margin-top: 10px;
    }
}
/* ----------------         ipad version              -------------------- */
@media (max-width: 1179px) and (min-width: 768px) {
    .heropage .firstpart {
        background-size: contain;
    }
    .heropage .firstpart p {
        font-size: 40px;
        margin-left: 20px;
    }
    .heropage .firstpart h5 {
        margin-left: 20px;
    }
    .cardono {
        padding: 10px 20px !important;
    }
}
